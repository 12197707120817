/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.





//twitterfetch
var twitterFetch = function () {
    var _callback, _options;

    return {
        fetch: function (widgetId, options, callback) {
            _options = options;
            _callback = callback;
            var script = document.createElement("script");
            script.src = "https://cdn.syndication.twimg.com/widgets/timelines/" + widgetId
                + "?&lang=en&callback=twitterFetch.callback&suppress_response_codes=true&rnd=" + Math.random();
            document.head.appendChild(script);
        },

        callback: function (feed) {
            var div = document.createElement("div");
            var html = feed.body;
            if (_options.images !== true) {
                html = html.replace(/<img/g, '<x-img');
            }
            div.innerHTML = html;

            var tweets = Array.prototype.map.call(div.querySelectorAll(".timeline-Tweet-text"), function(el) {
                var txt = el.textContent;
                if (_options.plaintext !== true) {
                    txt = txt.replace('&', '&amp;').replace('<', '&lt;').replace('>', '&gt;');
                }
                if (_options.hyperlinks !== false) {
                    txt = txt.replace(/(https?:\/\/[a-zA-Z0-9\/\&\?=\[\]%~#;\$\-_\+!\*',":\.\(\)]+[a-zA-Z0-9\/\&\?=\[\]%~#;\$\-_\+!\*',":]{2})/g,
                            '<a href=\'$1\'>$1</a>')
                        .replace(/@([0-9a-zA-Z_-]+)/g, '<a href=\'http://twitter.com/$1\'>@$1</a>');
                }
                return txt;
            });

            _callback(tweets)
        }
    }
} ();
 twitterFetch.fetch('723432873844334592', {}, function (tweets) {
	if (!tweets || tweets.length == 0) {
		return;
	}
	document.getElementById('tweets').innerHTML = tweets[0];
});

// instafeed
jQuery( document ).ready(function($) {

  //alert("TEST");
       console.log(location.hash);
       if (location.hash) {
           setTimeout( function() {
             var target = jQuery(location.hash);
             target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
             if (target.length) {
               jQuery('html,body').animate({
                 scrollTop: target.offset().top - 100
               },  900);

               $('[href="'+location.hash+'"]').parent().addClass('active');
               return false;
             }
           }, 500);
       }

     var feed = new Instafeed({
     get: 'user',
     userId: 179426380,
     accessToken: '179426380.1677ed0.1c67ae37d3204b8d828c2b61cf3f5675',
     resolution: 'standard_resolution',

     template: '<div class="item"><img src="{{image}}" /></div>',
     target: 'instafeed',
     after: function() {
     $('.owl-carousel').owlCarousel({
     loop:true,
     nav:false,
     autoplay:true,
     autoplayTimeout:5000,
     autoplayHoverPause:true,
     margin:0,
     responsiveClass:true,
     responsive:{
    	 0:{
    		items:1
    	 },
    	 600:{
    		items:3
    	 },
    	 1000:{
    		items:5
    	 }
     }
     });

     }
     });
     feed.run();

 });
jQuery('ul.nav li').click(function(){
      $('ul.nav li').removeClass('active');
      $(this).addClass('active');
});

jQuery('#fixed-navbar').affix({
      offset: {
        top: $('header').height()
      }
});

jQuery('#fixed-navbar').on('affix.bs.affix', function (){
     $('#affix-placeholder').css('margin-top', $('#fixed-navbar').height());
});

jQuery('#fixed-navbar').on('affix-top.bs.affix', function (){
     $('#affix-placeholder').css('margin-top', 0);
});

//jQuery for page scrolling feature - requires jQuery Easing plugin
jQuery('.page-scroll').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 100
        }, 900);
//        return false;
      }
    }
});

if (location.hash) {
  setTimeout( function() {
    window.scrollTo(0,0);
  }, 1);
}
